import { IndexService } from '../../service/index/index.service';
import { BrannerService } from '../../service/common/branner/branner.service';
import { BusinessService } from '../../service/common/business/business.service';
import { ServiceInfoService } from '../../service/common/service/serviceinfo.service';
import { SolutionService } from '../../service/index/solution.service';
import { ContentCategoriesService } from '../../service/information/content-categories.service';
import { NewsService } from '../../service/information/news.service';
import { Common } from '../../assets/scripts/common'

/** 首页 */
export class Index {
	indexService: IndexService = new IndexService();
	brannerService: BrannerService = new BrannerService();
	businessService: BusinessService = new BusinessService();
	serviceInfoService: ServiceInfoService = new ServiceInfoService();
	solutionService: SolutionService = new SolutionService();
	contentCategoriesService: ContentCategoriesService=new ContentCategoriesService(); 
	newsService:NewsService = new NewsService();
	constructor() { 

	}

	init() {
		// return new Promise((resolve, reject) => {
		// this.indexService
		// 	.getData(null)
		// 	.then((res) => {
		// 		resolve(res);
		// 	})
		// 	.catch((err) => {
		// 		resolve(err.error_message);
		// 	});
		// })
	}
	/**
	 * 获取广告列表
	 *
	 * @param {string} tagcode 标记码
	 * @memberof Index
	 */
	getBrannerList(tagcode: string) {
		let params = {
			banner_tagcode: tagcode
		};
		return new Promise((resolve, reject) => {
			this.brannerService
				.getData(params)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					resolve(err);
				});
		});
	}
		/** 获取服务信息类型列表 */
		getServiceTypeList() {
			return new Promise ((resolve, reject) => {
				this.serviceInfoService
					.getTypeList()
					.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						resolve(err);
					});
			});
		}
	/**
	 * 获取业务分类列表
	 *
	 * @param {string} tagcode 标记码
	 * @memberof Index
	 */
	getBusinessList(tagcode: string) {
		let params = {
			tagcode: tagcode
		};
		return new Promise((resolve, reject) => {
			this.businessService
				.getData(params)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					resolve(err);
				});
		});
	}
		/** 获取服务信息项目列表 */
		getServiceItemList(typeId: number) {
			return new Promise((resolve, reject) => {
				this.serviceInfoService
					.getData({ type_id: typeId })
					.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						resolve(err);
					});
			});
		}
		//获取解决方案列表
		getSolutionList() {
			return new Promise((resolve, reject) => {
				this.solutionService
					.getData(null)
					.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						resolve(err.error_message);
					});
			})
		}


			/**获取一级分类列表 */
	getlevelcategory(){
		let params={
			level:1
		}
		return new Promise((resolve, reject) => {
			this.contentCategoriesService
				.getData(params)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					resolve(err);
				});
		});	
	}
	/**根据分类ID获取下级分类*** */
	getNextLevelcategory(id){
		return new Promise((resolve, reject) => {
			this.contentCategoriesService
				.getSubordinate(id)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					resolve(err);
				});
		});	
	}
	/****获取某一内容分类信息 */
	getLevelcategoryDetails(id){
		return new Promise((resolve, reject) => {
			this.contentCategoriesService
				.getDetails(id)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					resolve(err);
				});
		});	
	}

	/****获取新闻列表 */
	getNewsList(id,pageIndex,pageSize){
		let params={
			content_category_id:id,
			index:pageIndex,
			size:pageSize,
		}
		return new Promise((resolve, reject) => {
			this.newsService
				.getData(params)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					resolve(err);
				});
		});	
	}
}

window['pageVariable'] = {};
window['pageVariable'].Index = new Index();
