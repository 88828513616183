import {WebBaseService} from './webbase.service';
import axios from 'axios';

interface Options {
	url: string;
	data?: any;
}
interface DeleteOptions {
	url: string;
	id?: string | number;
	data?: any;
}
interface DeleteMultipleOptions {
	url: string;
	ids: {
		key: string;
		value: Array<string | number>;
	};
}
interface DeleteMultipleOptions {
	url: string;
	ids: {
		key: string;
		value: Array<string | number>;
	};
}
export class RequestService {
	successHandler: any;
	tokenInvalid: boolean = false; //token失效标记
	
	constructor() {}
	/**
     * @param getUrl判断api前是否有'/'
     */
	getUrl(url: string): string {
		if (url.indexOf('/') == 0) {
			return WebBaseService.apiUrl + url;
		} else if (url.indexOf(WebBaseService.apiUrl) > -1) {
			return url;
		} else {
			return WebBaseService.apiUrl + '/' + url;
		}
	}
	get(options: Options): Promise<any> {
		var url = this.getUrl(options.url);
		var data = options.data;
		if (data) {
			url = url + '?';
			for (var k in data) {
				url += k + '=' + data[k] + '&';
			}
			var arr = url.split('');
			arr.pop();
			url = arr.join('');
		}
		/**encodeURI() 函数可把字符串作为 URI 进行编码。不会进行转义以下： , / ? : @ & = + $ # */
		url = encodeURI(url);
		var storage = window.sessionStorage;
		var token = storage.getItem('token');
		return axios
				.get(url,{headers:{ 'Content-type': 'application/json;charset=utf-8',
				Authorization:token}})
				.then((response) => {
					return(response.data);
				})
				.catch((error) => {
					return(error);
				});
	}
	post(options: Options): Promise<any> {
		let $this=this
		var url = this.getUrl(options.url);
		var data = options.data || {};
		var storage = window.sessionStorage;
		var token = storage.getItem('token');
		return axios.post(url, data,{headers:{ 'Content-type': 'application/json;charset=utf-8',
                 Authorization:token}})
				.then((response) => {
					return(response);
				})
				.catch((error) => {
					return(error);
			});
	}
	put(options: Options): Promise<any> {
		var url = this.getUrl(options.url);
		var data = options.data || {};
		var storage = window.sessionStorage;
		var token = storage.getItem('token');
		return axios.put(url, data,{headers:{ 'Content-type': 'application/json;charset=utf-8',
			Authorization:token}})
			.then((response) => {
				return response
			})
			.catch((error) => {
				return error
			})
		
	}

	delete(options: DeleteOptions): Promise<any> {
		var storage = window.sessionStorage;
		var token = storage.getItem('token');
		var url = this.getUrl(options.url);
		if (options.id) {
			url += '/' + options.id;
		}
		var data = options.data;
		if (data) {
			url = url + '?';
			for (var k in data) {
				url += k + '=' + data[k] + '&';
			}
			var arr = url.split('');
			arr.pop();
			url = arr.join('');
        }
        url = encodeURI(url)
		return	axios.delete(url,{headers:{ 'Content-type': 'application/json;charset=utf-8',
				Authorization:token}})
				.then((response) => {
					return response
				})
				.catch((error) => {
					return error
				});
		;
	}
}
