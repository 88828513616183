import { RequestService } from "../../request.service";
import { BaseService } from "../../base.service";


export class ServiceInfoService extends RequestService implements BaseService {
    url :string
    constructor(){
        super()
        this.url = '/api/v1/service'
    }
    getTypeList(): Promise<any> {
        let url = this.url+'/types';
        return this.get({url:url})
     }
    getData(params?: any): Promise<any> {
       return this.get({url:this.url,data:params})
    }
}