import { RequestService } from "../../request.service";
import { BaseService } from "../../base.service";


export class BrannerService extends RequestService implements BaseService {
    url :string
    constructor(){
        super()
        this.url = '/api/v1/banner'
    }
    getData(params?: any): Promise<any> {
       return this.get({url:this.url,data:params})
    }
}