import { RequestService } from "../request.service";
import { BaseService } from "../base.service";

/**获取解决方案列表 */
export class SolutionService extends RequestService implements BaseService {
    url :string
    constructor(){
        super()
        this.url = '/api/v1/solution'
    }
    getData(params?: any): Promise<any> {
       return this.get({url:this.url,data:params})
    }
    add?(params: any): Promise<any> {
        return this.post({url:this.url,data:params})
    }
    update?(id: string | number, params: any): Promise<any> {
        return this.put({url:this.url,data:params})
    }
    deleteById?(id: string | number): Promise<any> {
        let url = this.url+'/'+id
        return this.delete({url:url})
    }
    

}