import { RequestService } from "../request.service";
import { BaseService } from "../base.service";
/**新闻资讯 */

export class ContentCategoriesService extends RequestService implements BaseService {
    url: string
    constructor() {
        super()
        this.url = '/api/v1/contentcategories'
    }
    /******获取新的获取一级分类列表 /api/v1/contentcategories/levelcategory*/
    getData(params?: any): Promise<any> {
        let urls = this.url+'/levelcategory'
        return this.get({ url: urls, data: params })
    }
    /***GET /api/v1/contentcategories/{content_category_id}获取某一内容分类信息******* */
    getDetails(id:number|string): Promise<any> {
        let urls = this.url+'/'+id
        return this.get({ url: urls})
    }
    /***api/v1/contentcategories/nextcategory/{content_category_id}根据分类ID获取下级分类** */
    getSubordinate(id:number|string): Promise<any> {
        let urls = this.url+'/nextcategory/'+id
        return this.get({ url: urls })
    }
}