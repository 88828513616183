import { RequestService } from "../request.service";
import { BaseService } from "../base.service";
/**新闻资讯 */

export class NewsService extends RequestService implements BaseService {
    url: string
    constructor() {
        super()
        this.url = '/api/v1/newscontent'
    }
    //获取新闻内容列表 
    getData(params?: any): Promise<any> {
        let urls = this.url
        return this.get({ url: urls, data: params })
    }
    //获取一条新闻内容 /api/v1/newscontent/{news_content_id}
    getDetails?(id: number | string): Promise<any> {
        let urls = this.url + '/' + id
        return this.get({ url: urls })
    }
    //更新转载次数 PUT /api/v1/newscontent/{news_content_id}
    updateCount?(id: number | string): Promise<any> {
        let urls = this.url + '/' + id
        return this.put({ url: urls })
    }
    //获取内容简要列表 GET /api/v1/newscontent/getcontentlist
    getcontentlist(params?: any): Promise<any> {
        let urls = this.url + '/getcontentlist'
        return this.get({ url: urls, data: params })
    }
    //获取新闻内容GET /api/v1/newscontent/getnewbyid
    getnewbyid?(params?: any): Promise<any> {
        let urls = this.url + '/getnewbyid'
        return this.get({ url: urls, data: params })
    }
    //根据关键字查询新闻 /api/v1/newscontent/querynewsbykeyword
    querynewsbykeyword(params?: any): Promise<any> {
        let urls = this.url + '/querynewsbykeyword'
        return this.get({ url: urls, data: params })
    }
    //查询上一篇或者下一篇 GET /api/v1/newscontent/querypreviouornext
    querypreviouornext(params?: any): Promise<any> {
        let urls = this.url + '/querypreviouornext'
        return this.get({ url: urls, data: params })
    }
    //新闻点赞或者取消点赞 PUT /api/v1/newscontentcomment/like/{news_content_comment_id}
    like(id: string | number, params: any): Promise<any> {
        let urls = this.url + '/like/' + id
        return this.get({ url: urls, data: params })
    }
}